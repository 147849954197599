import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from "./pages/About";
import Home from "./pages/Home";
import SideBar from "./components/SideBar";
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import { FormControlLabel } from '@mui/material';
import { MaterialUISwitch } from './components/StyledThemeSwitcher';



function App() {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "dark";
  });

  const toggleTheme = () => {
    setTheme((current) => (current === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div className="main-container" id={theme}>
      <Router>
        <SideBar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <div className="switch">
        <label className="label">
          {theme === "dark" ? "Dark Mode" : "Light Mode"}
        </label>
        <FormControlLabel
          checked={theme === "dark"}
          onChange={toggleTheme}
          control={<MaterialUISwitch sx={{ m: 1 }} />}
        />
      </div>
    </div>
  );
}

export default App;
